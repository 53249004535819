jQuery(document).ready(function ($) {
    var animation_speed = 250;
    $('.main-header-menu .navbar-nav > li').mouseover(function () {
        $(this).addClass('hover').siblings().removeClass('hover');
        var positionLeft = parseFloat($(this).position().left) + parseFloat($(this).outerWidth(true) - $(this).outerWidth());
        $('.navbar-active-indicator-container .navbar-active-indicator').stop().animate({
            width: $(this).outerWidth(),
            left: positionLeft,
            bottom: 0
        }, {
            duration: animation_speed,
            queue: false
        });
    }).mouseout($.debounce(1000, function () {
        $('.main-header-menu .navbar-nav > li.active').trigger('mouseover');
    })).find('a').click(function(e){
        $(this).parent().addClass('active').siblings().removeClass('active');
    });
    $('.main-header-menu .navbar-nav > li.active').trigger('mouseover');

    $('.andante-file-widget').andanteFileWidget();
    $(document).on('click', 'a', function (event) {

        var href = $.attr(this, 'href');
        if (href.charAt(0) === '#') {
            event.preventDefault();
            var offset = 0;
            if($('.header-container').is(':visible')){
                offset = $('.header-container').height();
            }
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - offset
            }, 500);
        }
    });
});